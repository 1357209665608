import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { ChatBotForm } from "@/components/chatbot-form.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card.tsx"
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog.tsx"
import { H1 } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { Loader2 } from "lucide-react"
import { LoaderFunctionArgs, useLoaderData, useNavigate } from "react-router"
import invariant from "tiny-invariant"

export async function chatBotEditPageLoader({ params }: LoaderFunctionArgs) {
  authenticateGuard(["admin"])
  invariant(params.chatBotId, "chatBotId is required")
  let chatBot = await trpc.chatbots.getBySlug.query({ slug: params.chatBotId })
  if (!chatBot) {
    chatBot = await trpc.chatbots.get.query({ chatBotId: params.chatBotId })
  }
  invariant(chatBot, "Chat Bot not found")
  const knowledge = await trpc.knowledge.list.query()
  return { chatBot, knowledge }
}

type LoaderData = Awaited<ReturnType<typeof chatBotEditPageLoader>>

export function ChatBotEditPage() {
  const data = useLoaderData() as LoaderData

  const navigate = useNavigate()

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Administration",
          },
          {
            title: "ChatBots",
            href: "/admin/chatbots",
          },
          {
            title: data.chatBot.name,
          },
        ]}
      />
      <Main>
        <H1 className="mb-8">Update ChatBot</H1>
        <ChatBotForm
          chatBot={data.chatBot}
          knowledge={data.knowledge}
          onSubmit={async (values) => {
            await trpc.chatbots.update.mutate({
              id: data.chatBot.id,
              name: values.name,
              description: values.description,
              model: values.model ?? "gpt-4o",
              internal: values.internal,
              role: values.role,
              context: values.context,
              rules: values.rules,
              openingMessage: values.openingMessage,
              returnMessage: values.returnMessage,
              emailWhitelist: values.emailWhitelist,
              userMessageHandling: values.userMessageHandling,
              knowledgeIds: values.knowledge.map((k) => k.id),
              assetIds: values.assets.map((a) => a.id),
              slug: values.slug,
              capabilities: {
                datalake: values.datalake,
                contextPanel: values.contextPanel,
                chatHistory: values.chatHistory,
                chatThreads: values.chatThreads,
                fileUploads: values.fileUploads,
              },
            })
            // Chatbots show up in nav, so force navigate
            window.location.href = "/admin/chatbots"
          }}
          actions={(state) => (
            <>
              <div className="flex flex-grow justify-between">
                <div className="flex gap-3">
                  <Button
                    type="submit"
                    variant="default"
                    disabled={state.isSubmitting || state.isLoading || state.isUploading}
                  >
                    {(state.isSubmitting || state.isLoading || state.isUploading) && (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    )}
                    Update ChatBot
                  </Button>
                  <Button
                    type="button"
                    variant="secondary"
                    disabled={state.isSubmitting || state.isLoading || state.isUploading}
                    onClick={() => navigate("/admin/chatbots")}
                  >
                    Cancel
                  </Button>
                </div>
                <div>
                  <Dialog>
                    <DialogTrigger asChild={true}>
                      <Button
                        type="button"
                        variant="destructive"
                        disabled={state.isSubmitting || state.isLoading || state.isUploading}
                      >
                        Delete ChatBot
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogTitle>Confirmation Needed</DialogTitle>
                      <DialogDescription>
                        You have requested that this ChatBot be removed from our system. This action
                        cannot be reversed. Please confirm the deletion below, or cancel if you wish
                        to keep the ChatBot.
                      </DialogDescription>
                      <DialogFooter>
                        <Button
                          variant="destructive"
                          disabled={state.isSubmitting || state.isLoading}
                          onClick={async () => {
                            await trpc.chatbots.remove.mutate({ chatBotId: data.chatBot.id })
                            // chatbots are in main nav, so force referesh
                            window.location.href = `/admin/chatbots`
                          }}
                        >
                          {(state.isSubmitting || state.isLoading) && (
                            <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                          )}
                          Confirm Delete
                        </Button>
                        <DialogClose asChild={true}>
                          <Button
                            disabled={state.isSubmitting || state.isLoading || state.isUploading}
                            variant="secondary"
                          >
                            Cancel
                          </Button>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </div>
              </div>

              <Card className="mt-48 opacity-40">
                <CardHeader>
                  <CardTitle>Engineering Debug Panel</CardTitle>
                  <CardDescription>
                    This section provides debugging information, which can be used by engineers to
                    help support issues with ChatBots.
                  </CardDescription>
                </CardHeader>
                <CardContent className="grid gap-6">
                  <Button asChild={true} variant="secondary" size="sm">
                    <a
                      href={`https://platform.openai.com/assistants/${data.chatBot.openaiAssistantId}`}
                      target="_blank"
                    >
                      View OpenAI Assistant
                    </a>
                  </Button>
                </CardContent>
              </Card>
            </>
          )}
        />
      </Main>
    </>
  )
}
