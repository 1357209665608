import { cn } from "@/lib/tw-utils.ts"
import React from "react"

export type InlineEditProps = {
  value: string
  onChange: (value: string) => void
  className?: string
  inputClassName?: string
  displayClassName?: string
}

export function InlineEdit(props: InlineEditProps) {
  const [isEditing, setIsEditing] = React.useState(false)

  return isEditing ? (
    <input
      type="text"
      value={props.value}
      onChange={(e) => props.onChange(e.target.value)}
      onBlur={() => setIsEditing(false)}
      onFocus={(e) => e.target.select()}
      className={cn(
        "border-none bg-transparent text-sm outline-none",
        props.className,
        props.inputClassName,
      )}
      autoFocus
    />
  ) : (
    <span
      onClick={() => setIsEditing(true)}
      className={cn(
        "cursor-pointer text-sm hover:bg-gray-50/20",
        props.className,
        props.displayClassName,
      )}
    >
      {props.value}
    </span>
  )
}
