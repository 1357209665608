import SparkMD5 from "spark-md5"

export async function calculateMd5(file: File): Promise<string> {
  const buffer = await file.arrayBuffer()
  const spark = new SparkMD5.ArrayBuffer()
  spark.append(buffer)
  return spark.end()
}

export function hexToBase64(hex: string) {
  const bytes = hex.match(/../g)!.map((h) => parseInt(h, 16))
  return btoa(String.fromCharCode(...bytes))
}
