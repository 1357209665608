import { BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { KnowledgeForm } from "@/components/knowledge-form.tsx"
import { Main } from "@/components/layout.tsx"
import { Button } from "@/components/ui/button.tsx"
import { H1, Lead } from "@/components/ui/typography.tsx"
import { authenticateGuard } from "@/context/auth.tsx"
import { trpc } from "@/lib/trpc.ts"
import { useNavigate } from "react-router"

export async function knowledgeCreatePageLoader() {
  authenticateGuard(["admin"])
  return null
}

export function KnowledgeCreatePage() {
  const navigate = useNavigate()

  return (
    <>
      <BreadcrumbsHeader
        items={[
          {
            title: "Administration",
          },
          {
            title: "Knowledge",
            href: "/admin/knowledge",
          },
          {
            title: "Create",
          },
        ]}
      />
      <Main>
        <H1 className="mb-4">Create Knowledge</H1>
        <Lead className="mb-8 text-balance">Create a new Knowledge base below.</Lead>

        <KnowledgeForm
          onSubmit={async (values) => {
            await trpc.knowledge.create.mutate({
              name: values.name,
              description: values.description,
              assetIds: values.assets.map((f) => f.id),
            })
            navigate("/admin/knowledge")
          }}
          actions={(formState) => (
            <div className="flex flex-grow justify-between">
              <div className="flex gap-3">
                <Button
                  type="submit"
                  variant="default"
                  disabled={formState.isSubmitting || formState.isLoading || formState.isUploading}
                >
                  Create Knowledge
                </Button>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={formState.isSubmitting || formState.isLoading || formState.isUploading}
                  onClick={() => {
                    navigate("/admin/knowledge")
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          )}
        />
      </Main>
    </>
  )
}
