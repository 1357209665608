import { Paperclip } from "lucide-react"
import React from "react"
import { Button } from "./ui/button.tsx"

type FileSelectError = {
  file: File
  error: string
}

export type SimpleFileSelectorProps = {
  disabled?: boolean
  variant?: "button" | "icon"
  /**
   * An array of file types that are allowed to be selected.
   * For example, to allow only PNG and JPEG files, set this to `[".png", ".jpeg", ".jpg"]`.
   **/
  fileTypes: string[]
  multiple?: boolean
  fileSizeLimitInBytes: number
  onFileSelect: (files: File[], errors: FileSelectError[]) => void
}

export function SimpleFileSelector(props: SimpleFileSelectorProps) {
  const handleFileChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files

      const validFiles: File[] = []
      const errors: FileSelectError[] = []

      if (!files) {
        props.onFileSelect([], [])
        return
      }

      for (const file of files) {
        if (file.size > props.fileSizeLimitInBytes) {
          errors.push({
            file,
            error: `File size exceeds maximum. Actual: ${Math.round(file.size / 1024 / 1024)}MB, Limit: ${Math.round(props.fileSizeLimitInBytes / 1024 / 1024)}MB`,
          })
        } else {
          validFiles.push(file)
        }
      }

      props.onFileSelect(validFiles, errors)
    },
    [props.onFileSelect],
  )

  const inputRef = React.useRef<HTMLInputElement | null>(null)

  return (
    <label className="inline cursor-pointer" title="Select file">
      {props.variant === "button" ? (
        <Button
          onClick={(e) => {
            e.preventDefault()
            inputRef.current?.click()
          }}
          disabled={props.disabled}
        >
          Add Files
        </Button>
      ) : (
        <Paperclip />
      )}
      <input
        ref={inputRef}
        disabled={!!props.disabled}
        type="file"
        multiple={!!props.multiple}
        accept={props.fileTypes?.join(",")}
        onChange={handleFileChange}
        style={{ display: "none" }}
      />
    </label>
  )
}
