export function timeAgo(date: Date | string): string {
  const convertedDate = typeof date === "string" ? new Date(date + "Z") : date
  const now = new Date()
  const diffInMilliseconds = now.getTime() - convertedDate.getTime()
  const diffInSeconds = Math.floor(diffInMilliseconds / 1000)
  const diffInMinutes = Math.floor(diffInSeconds / 60)
  const diffInHours = Math.floor(diffInMinutes / 60)
  const diffInDays = Math.floor(diffInHours / 24)
  const diffInMonths = Math.floor(diffInDays / 30)
  const diffInYears = Math.floor(diffInDays / 365)

  if (diffInSeconds < 60) {
    return "Just now"
  }

  if (diffInMinutes < 60) {
    return `${diffInMinutes} ${diffInMinutes === 1 ? "minute" : "minutes"} ago`
  }

  if (diffInHours < 24) {
    return `${diffInHours} ${diffInHours === 1 ? "hour" : "hours"} ago`
  }

  if (diffInDays === 1) {
    return "Yesterday"
  }

  if (diffInDays < 30) {
    return `${diffInDays} days ago`
  }

  if (diffInMonths < 12) {
    return `${diffInMonths} ${diffInMonths === 1 ? "month" : "months"} ago`
  }

  return `${diffInYears} ${diffInYears === 1 ? "year" : "years"} ago`
}
