import { BreadcrumbItem, BreadcrumbsHeader } from "@/components/breadcrumbs-header.tsx"
import { Chatbot } from "@/components/chatbot.tsx"
import { InlineEdit } from "@/components/inline-edit.tsx"
import { useLogAnalyticsEvent } from "@/context/analytics.tsx"
import { authenticateGuard, authHeaders } from "@/context/auth.tsx"
import { calculateMd5, hexToBase64 } from "@/lib/files.ts"
import { trpc } from "@/lib/trpc.ts"
import { log } from "@/log.ts"
import { ChatBotConverse } from "@ai/core/chatbots/chatbot-converse.ts"
import { CHUNK_SEPARATOR } from "@ai/core/chatbots/constants.ts"
import type { ChatBots } from "@ai/core/chatbots/index.ts"
import hyperid from "hyperid"
import React from "react"
import { LoaderFunctionArgs, useLoaderData } from "react-router"
import { debounce } from "throttle-debounce"
import invariant from "tiny-invariant"

const MAX_MESSAGES_BUFFER_SIZE = 24
const PREVIOUS_MESSAGES_BUFFER_SIZE = 24

const createId = hyperid()

export async function chatBotPageLoader({ params }: LoaderFunctionArgs) {
  const account = authenticateGuard()
  invariant(params.chatBotId, "chatBotId is required")

  // get by slug first, if not found, get by id
  let chatBot = await trpc.chatbots.getBySlug.query({ slug: params.chatBotId })
  if (!chatBot) {
    chatBot = await trpc.chatbots.get.query({ chatBotId: params.chatBotId })
  }
  invariant(chatBot, "Chat Bot not found")

  const conversationId = params.conversationId

  const loadConversation = async () => {
    if (typeof conversationId === "string") {
      const conversation = await trpc.chatbots.getConversation.query({
        chatBotId: chatBot.id,
        conversationId: conversationId,
        recentMessagesLimit: MAX_MESSAGES_BUFFER_SIZE + PREVIOUS_MESSAGES_BUFFER_SIZE,
      })
      if (!conversation) {
        throw new Response("Not Found", { status: 404 })
      }
      return conversation
    }
    return await trpc.chatbots.getMostRecentConversation.query({
      chatBotId: chatBot.id,
      recentMessagesLimit: MAX_MESSAGES_BUFFER_SIZE + PREVIOUS_MESSAGES_BUFFER_SIZE,
    })
  }

  let conversation = await loadConversation()

  if (!conversation || !chatBot.capabilities.chatHistory) {
    conversation = await trpc.chatbots.createConversation.mutate({
      chatBotId: chatBot.id,
    })
  }

  // for some reason the `reverse` call breaks the types. so we recast it
  const messages = conversation.recentMessages.reverse() as ChatBots.ConversationMessage[]

  const recentMessages = !messages
    ? []
    : messages.length > MAX_MESSAGES_BUFFER_SIZE
      ? messages.slice(messages.length - MAX_MESSAGES_BUFFER_SIZE)
      : messages
  const previousMessagesBuffer = !messages
    ? []
    : recentMessages.length == MAX_MESSAGES_BUFFER_SIZE
      ? messages.slice(0, messages.length - MAX_MESSAGES_BUFFER_SIZE)
      : []

  return {
    userEmail: account.email,
    chatBot,
    conversation,
    previousMessagesBuffer,
    recentMessages,
  }
}

type LoaderData = Awaited<ReturnType<typeof chatBotPageLoader>>

export function ChatBotPage() {
  const data = useLoaderData() as LoaderData

  const logAnalyticsEvent = useLogAnalyticsEvent()

  const [messages, setMessages] = React.useState<ChatBots.ConversationMessage[]>(
    () => data.recentMessages,
  )

  const [prevMessagesBuffer, setPrevMessagesBuffer] = React.useState<
    ChatBots.ConversationMessage[]
  >(data.previousMessagesBuffer)

  const [conversationTitle, setConversationTitle] = React.useState<string | null>(
    data.conversation?.title,
  )

  const [conversations, setConversations] = React.useState<
    {
      id: string
      timeCreated: string
      title: string | null
    }[]
  >([])

  React.useEffect(() => {
    setMessages(data.recentMessages)
    setPrevMessagesBuffer(data.previousMessagesBuffer)
    setConversationTitle(data.conversation?.title ?? null)
    setConversations([])
  }, [data.chatBot.id, data.conversation?.id])

  React.useEffect(() => {
    async function loadConversations() {
      // TODO: Pagination support!
      const conversations = await trpc.chatbots.listConversationsMeta.query({
        chatBotId: data.chatBot.id,
        limit: 50,
      })
      setConversations(conversations)
    }
    if (!data.chatBot.capabilities.chatThreads) return

    loadConversations()
  }, [data.chatBot.id, data.conversation?.id])

  // Auto-generate conversation title (if not exists and we have enough messages)
  React.useEffect(() => {
    const act = async () => {
      const conversationId = data.conversation.id
      const conversationTitle = await trpc.chatbots.generateTitleForConversation.mutate({
        chatBotId: data.chatBot.id,
        conversationId,
      })
      if (!conversationTitle) {
        return
      }
      setConversationTitle(conversationTitle)
      setConversations((current) =>
        current.map((c) => {
          if (c.id === conversationId) {
            return { ...c, title: conversationTitle }
          }
          return c
        }),
      )
    }
    if (
      data.chatBot.capabilities.chatThreads &&
      !data.conversation.titleCustomized &&
      !data.conversation?.title &&
      (messages.length == 12 || messages.length == 20)
    ) {
      act()
    }
  }, [data.chatBot, data.conversation, messages])

  // If the user updates the conversation title, we must update the sidebar list too
  React.useEffect(() => {
    if (data.conversation?.id && conversations && conversationTitle) {
      const conversation = conversations.find((c) => c.id === data.conversation.id)
      if (conversation && conversation.title !== conversationTitle) {
        setConversations(
          conversations.map((c) => {
            if (c.id === data.conversation.id) {
              return { ...c, title: conversationTitle }
            }
            return c
          }),
        )
      }
    }
  }, [data.conversation?.id, conversations, conversationTitle])

  const [selectedFiles, setSelectedFiles] = React.useState<File[]>([])

  const [status, setStatus] = React.useState<string | undefined>(undefined)

  const onLoadPrevMessages = React.useCallback(
    async (onPrevMessagesLoaded: () => void) => {
      const [oldestMessage] = prevMessagesBuffer
      if (!oldestMessage || !data.conversation?.id) {
        return
      }
      setMessages(() => [...prevMessagesBuffer, ...messages])
      const nextPrevMessages = (await trpc.chatbots.getConversationMessagesBefore.query({
        conversationId: data.conversation.id,
        messageId: oldestMessage.id,
        limit: PREVIOUS_MESSAGES_BUFFER_SIZE,
      })) as ChatBots.ConversationMessage[]
      setPrevMessagesBuffer(nextPrevMessages)

      requestAnimationFrame(() => {
        onPrevMessagesLoaded()
      })
    },
    [prevMessagesBuffer, messages],
  )

  const batchIdx = React.useRef<number>(0)

  const [waitingForResponse, setWaitingForResponse] = React.useState(false)
  const [streamingResponse, setStreamingResponse] = React.useState(false)

  const processResponsePart = React.useCallback(
    async (part: ChatBotConverse.ChatBotResponsePart) => {
      switch (part.type) {
        case "text": {
          setMessages((currentMessages) => {
            const lastMessage = currentMessages[currentMessages.length - 1]
            const now = new Date().toISOString()
            const messageId = createId()
            const textPart: ChatBots.ConversationMessagePart = {
              id: createId(),
              timeCreated: now,
              timeUpdated: now,
              order: 0,
              type: "text",
              data: {},
              text: part.text,
              messageId,
            }

            // New assistant message case
            if (!lastMessage || lastMessage.role !== "assistant") {
              return [
                ...currentMessages,
                // Add a new message as this is the first part of an assistant response
                {
                  id: messageId,
                  role: "assistant" as const,
                  conversationId: data.conversation.id,
                  parts: [textPart],
                  timeCreated: now,
                  timeUpdated: now,
                },
              ]
            }

            // Append to existing message case
            const lastPart = lastMessage.parts[lastMessage.parts.length - 1]
            const otherMessages = currentMessages.slice(0, currentMessages.length - 1)

            // If last part isn't text, just append
            if (!lastPart || lastPart.type !== "text") {
              return [
                ...otherMessages,
                {
                  ...lastMessage,
                  parts: [
                    ...lastMessage.parts,
                    { ...textPart, order: lastMessage.parts.length + 1 },
                  ],
                },
              ]
            }

            // Merge with previous text
            const otherParts = lastMessage.parts.slice(0, lastMessage.parts.length - 1)
            return [
              ...otherMessages,
              {
                ...lastMessage,
                parts: [...otherParts, { ...lastPart, text: lastPart.text + part.text }],
              },
            ]
          })
          break
        }
        case "data": {
          setMessages((currentMessages) => {
            const partData = part.data

            if (partData.type !== "datatable") {
              throw new Error(`Invalid data type: ${part.data.type}`)
            }

            const lastMessage = currentMessages[currentMessages.length - 1]
            const now = new Date().toISOString()
            const messageId = createId()

            const dataPart: ChatBots.ConversationMessagePart = {
              id: createId(),
              messageId,
              timeCreated: now,
              timeUpdated: now,
              order: 0,
              type: "data",
              data: partData,
              text: "",
            }

            // New assistant message case
            if (!lastMessage || lastMessage.role !== "assistant") {
              return [
                ...currentMessages,
                {
                  id: messageId,
                  role: "assistant" as const,
                  conversationId: data.conversation.id,
                  parts: [dataPart],
                  timeCreated: now,
                  timeUpdated: now,
                },
              ]
            }

            // Append to existing message case
            const otherMessages = currentMessages.slice(0, currentMessages.length - 1)
            return [
              ...otherMessages,
              {
                ...lastMessage,
                parts: [...lastMessage.parts, { ...dataPart, order: lastMessage.parts.length + 1 }],
              },
            ]
          })
          break
        }
        case "error": {
          // TODO: Is this ok? Calling a React.useCallback in a React.useCallback?
          await processResponsePart({
            type: "text",
            text: `

Sorry, I've had encountered an issue. I'm not able to completed my response at this moment. Please try again later.`,
          })
          break
        }
        default: {
          throw new Error(`Invalid part type: ${part.type}`)
        }
      }
    },
    [setMessages, data.chatBot.id, data.conversation.id],
  )

  const onSendUserPrompt = React.useCallback(
    async (prompt: string) => {
      // Are we are already waiting for a response?
      if (waitingForResponse) {
        console.log("onSendUserPrompt: Not sending message", { waitingForResponse })
        return
      }

      // If the User hasn't actually typed anything then don't do anything
      if (prompt.trim() === "") {
        console.log("onSendUserPrompt: Not sending empty message", { prompt })
        return
      }

      try {
        // Add the User message to the messages list
        const userMessageId = createId()
        const now = new Date().toISOString()
        const userMessage: ChatBots.ConversationMessage = {
          id: userMessageId,
          role: "user",
          conversationId: data.conversation.id,
          timeCreated: now,
          timeUpdated: now,
          parts: [
            {
              id: createId(),
              type: "text",
              text: prompt,
              order: 0,
              messageId: userMessageId,
              timeCreated: now,
              timeUpdated: now,
              data: {},
            },
          ],
        }
        setMessages((prevMessages) => [...prevMessages, userMessage])

        // Increment the batch index
        batchIdx.current += 1

        // Indicate to the UI that we will be waiting on the Assistant response
        setWaitingForResponse(true)

        let assetIds: string[] = []
        if (selectedFiles.length > 0) {
          setStatus("Uploading files...")
          assetIds = await Promise.all(
            selectedFiles.map(async (file) => {
              try {
                const md5 = await calculateMd5(file)
                const uploadMeta = await trpc.assets.prepare.mutate({
                  filename: file.name,
                  contentType: file.type,
                  contentMd5: md5,
                  sizeBytes: file.size,
                })

                const response = await fetch(uploadMeta.uploadUrl, {
                  method: "PUT",
                  body: file,
                  headers: {
                    "content-md5": hexToBase64(md5), // aws requires base64 encoded
                    "content-type": file.type.toLowerCase(),
                  },
                })

                if (!response.ok) {
                  const text = await response.text()
                  throw new Error(
                    `Upload failed:${JSON.stringify(
                      {
                        status: response.status,
                        statusText: response.statusText,
                        responseText: text,
                      },
                      null,
                      2,
                    )}`,
                  )
                }

                await trpc.assets.uploaded.mutate({ assetId: uploadMeta.assetId })

                return uploadMeta.assetId
              } catch (err) {
                console.error("Asset upload failed:", err)
                return null
              }
            }),
          ).then((results) => results.filter(Boolean) as string[])
        }

        const response = await fetch(import.meta.env.VITE_CHATBOT_URL, {
          method: "POST",
          headers: {
            ...authHeaders(),
            "Content-Type": "application/json",
            Accept: "text/plain",
          },
          body: JSON.stringify({
            conversationId: data.conversation.id,
            prompt,
            chatBotId: data.chatBot.id,
            assetIds,
          }),
        })

        if (!response.ok || !response.body) {
          throw new Error("Failed to send message")
        }

        logAnalyticsEvent("chatbot_message_sent", {
          chatBotId: data.chatBot.id,
          chatBotName: data.chatBot.name,
          conversationId: data.conversation.id,
        })
        // TODO: Remove umami?
        if (window.umami) {
          window.umami.track("ChatBot Message Sent", {
            chatBotId: data.chatBot.id,
            chatBotName: data.chatBot.name,
            conversationId: data.conversation.id,
            userEmail: data.userEmail,
          })
        }

        const reader = response.body.getReader()
        const decoder = new TextDecoder("utf-8")

        const readStream = async () => {
          log.debug(`Reading response stream`)
          const { done, value } = await reader!.read()
          let chunk = decoder.decode(value, { stream: !done })
          if (done) {
            log.debug(`Stream is done`)
          }
          return { done, chunk }
        }

        while (true) {
          const { chunk, done } = await readStream()

          let preppedChunk = chunk

          // This is special handling for local development. We use the `lambda-stream` package to enable running of streaming lambdas in development, but it doesn't do proper streaming in local development, and the responses are wrapped into a JSON structure.
          if (import.meta.env.VITE_LOCAL === "true") {
            if (typeof preppedChunk !== "string" || preppedChunk.trim().length === 0) {
              // do nothing
            } else {
              const response = JSON.parse(chunk.toString())
              preppedChunk = response.body
            }
          }

          if (preppedChunk.trim().length === 0) {
            // do nothing
          } else {
            const parts = preppedChunk.split(CHUNK_SEPARATOR).reduce((acc: any, part: string) => {
              if (typeof part !== "string" || part.trim().length === 0) {
                return acc
              }
              acc.push(JSON.parse(part))
              return acc
            }, [])

            for (const part of parts) {
              await processResponsePart(part)
            }
          }

          // Is the streaming done?
          if (done) {
            break
          }
        }

        reader.releaseLock()
      } catch (error) {
        console.error("Error streaming response")
        console.error(error)
        await processResponsePart({
          type: "text",
          text: `

Sorry, I've had encountered an issue. I'm not able to completed my response at this moment. Please try again later.`,
        })
      } finally {
        // Unlock the UI
        setSelectedFiles([])
        setStreamingResponse(() => false)
        setWaitingForResponse(() => false)
        setStatus(() => undefined)
      }
    },
    [setMessages, data.chatBot.id, data.conversation.id, selectedFiles],
  )

  const saveConversationTitle = React.useCallback(
    async (title: string) => {
      await trpc.chatbots.updateConversationTitle.mutate({
        chatBotId: data.chatBot.id,
        conversationId: data.conversation.id,
        title,
      })
    },
    [data.chatBot.id, data.conversation.id],
  )

  const debounceSaveConversationTitle = React.useMemo(() => {
    const fn = debounce(1500, (newValue: string) => {
      if (newValue.length >= 5 && newValue.length <= 50) {
        saveConversationTitle(newValue)
      }
    })
    return fn
  }, [saveConversationTitle])

  const breadcrumbs = React.useMemo(() => {
    const items: BreadcrumbItem[] = [
      {
        title: "ChatBots",
        href: "/",
      },
      {
        title: data.chatBot.name,
      },
    ]

    if (data.chatBot.capabilities.chatThreads && data.conversation?.id) {
      items.push({
        element: (
          <InlineEdit
            key={data.conversation.id}
            value={conversationTitle ?? "Untitled Conversation"}
            onChange={(value) => {
              setConversationTitle(value)
              debounceSaveConversationTitle(value)
            }}
          />
        ),
      })
    }

    return items
  }, [data.chatBot, data.conversation, conversationTitle, debounceSaveConversationTitle])

  return (
    <>
      <Chatbot
        className="rounded-xl"
        chatBot={data.chatBot}
        hasPrevMessages={prevMessagesBuffer.length > 0}
        onLoadPrevMessages={onLoadPrevMessages}
        messages={messages}
        streamingResponse={streamingResponse}
        waitingForResponse={waitingForResponse}
        onSendUserPrompt={onSendUserPrompt}
        conversations={conversations}
        selectedFiles={selectedFiles}
        setSelectedFiles={setSelectedFiles}
        status={status}
      />
      <BreadcrumbsHeader items={breadcrumbs} />
    </>
  )
}
